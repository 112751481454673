@import url("./reset&custom.scss");
// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');


:root {
    --white: #FFF;
    --black: #000;
    --primary: linear-gradient(67deg, rgba(20, 169, 115, 0.80) -41.88%, #1ED699 126.58%);
    --secondary: #063725;
}

body {
    font-family: "Red Hat Display", sans-serif;
    color: var(--black);
}

@media (max-width: 767.98px) {
    .pt-120 {
        padding-top: 70px;
    }

    .pb-120 {
        padding-bottom: 70px;
    }

    .pt-100 {
        padding-top: 70px;
    }

    .pb-100 {
        padding-bottom: 70px;
    }

    .fs-18 {
        font-size: 16px;
    }

    .fs-15 {
        font-size: 13px;
    }
}

.container {
    max-width: 1280px;
}

.br-5 {
    border-radius: 5px;
}

.br-10 {
    border-radius: 10px;
}

.primary-btn {
    color: var(--white);
    background: var(--primary);
    border: 1px solid rgba(20, 169, 115, 0.80);
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        position: absolute;
        width: 90%;
        height: 42px;
        opacity: 0.4;
        background: linear-gradient(67deg, rgba(20, 169, 115, 0.8) -41.88%, #1ED699 126.58%);
        filter: blur(10px);
        bottom: -8px;
        margin: 0 auto;
        z-index: -1;
    }
}

.primary-border-btn {
    border: 1px solid rgba(20, 169, 115, 0.80);
    background: var(--primary);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.white-border-btn {
    color: var(--white);
    border: 1px solid var(--white);
}

.secondary-bg {
    background-color: var(--secondary);
    background-image: url("./Assets/images/Noise.svg");
    mix-blend-mode: multiply;
}

.primary-shape-bg {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background-image: url("./Assets/images/primary-shap.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top right;
        width: 1200px;
        height: 180px;
        @media (max-width: 1199.98px){
            width: 80%;
            height: 20%;
            object-fit: cover;
        }
    }

}

.row-gap-24 {
    row-gap: 24px;
}

.row-gap-30 {
    row-gap: 30px;
}

.row-gap-40 {
    row-gap: 40px;
}

.border-radius-10 {
    border-radius: 10px;
}

.border-radius-20 {
    border-radius: 20px;
}

p {
    line-height: 1.5;
    letter-spacing: .5px;
}


.btn-lg {
    display: inline-flex;
    padding: 17px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media (max-width: 991.98px) {
        padding: 15px 30px;
    }

    @media (max-width: 575.98px) {
        font-size: 15px;
    }
}

.btn-nav {
    display: inline-flex;
    padding: 0px 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 50px;

    @media (max-width: 991.98px) {
        padding: 10px 30px;
    }

}





// Header
.header-area {
    background: #FAFAFA;
    padding: 12px 0;

    @media (max-width: 767.98px) {
        display: none;
    }

    .header-address {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        ul {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        li {
            font-size: 16px;
            color: var(--black);
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
                width: 20px;
            }
        }
    }
}


// Main Menu
.main-menu {
    background: var(--white);
    padding: 30px 0;
    transition: all .2s;

    &.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        padding: 20px 0;
        box-shadow: 0px 5px 25px -25px rgba(0, 0, 0, 0.75);
    }

    @media (max-width: 1199.98px) {
        padding: 25px 0;
    }

    .main-menu-list {
        gap: 40px;

        @media (max-width: 1199.98px) {
            gap: 20px;
        }

        a {
            font-size: 18px;
            font-weight: 400;
            color: var(--black);

            @media (max-width: 1199.98px) {
                font-size: 16px;
            }

            &:hover,
            &.active {
                background: var(--primary);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                position: relative;
                font-weight: 600;
                letter-spacing: -.2px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    transform: translateY(-50%);
                    bottom: -5px;
                    width: 5px;
                    height: 5px;
                    background: var(--primary);
                    border-radius: 40px;

                }
            }
        }
    }

    .navbar-brand {
        padding: 0;
        padding-right: 50px;
        margin: 0;
        position: relative;

        @media (max-width: 1199.98px) {
            padding-right: 30px;
        }

        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 49px;
            background: #DFDFDF;

        }
    }

    .btn-nav {
        @media (max-width: 1199.98px) {
            padding: 12px 25px;
        }
    }

}

.main-menu-list {
    a {
        &.active {
            background: var(--primary);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
        }
    }

}

// Footer
.footer-area {

    form {
        label {
            display: block;
        }
    }

    .search-box {
        background: var(--white);
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        input {
            height: 60px;
            width: 100%;
            padding: 17px;
            padding-right: 120px;
            font-weight: 500;

            &::placeholder {
                color: rgba(6, 55, 37, 0.10);
            }
        }

        .primary-btn {
            border-radius: 10px;
            height: 60px;
            padding: 0 25px;
            position: absolute;
            right: 0
        }
    }

    .footer-wedged {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 35px;

        ul {
            display: grid;
            gap: 16px;

            a {
                color: var(--white);
                font-size: 16px;
                padding: 3px 0;
                transition: all .3s;
                display: flex;
                align-items: center;
                gap: 10px;
                line-height: 1.4;

                &:hover {
                    background: var(--primary);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    .copyright {
        border-top: 1px solid #D3D3D3;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 30px 0 40px;
        gap: 10px;

        @media (max-width: 767.98px) {
            justify-content: center;
        }
    }
}

.black-logo {
    display: none;
}

.footer-white {
    .black-logo {
        display: block;
    }

    .white-logo {
        display: none;
    }

    .footer-area {
        background: none;

        .text-white {
            color: var(--black) !important;
        }

        .footer-wedged ul a {
            color: var(--black);
        }

        .search-box input {
            background: #FAFAFA;
        }
    }
}


// ============= Home =========
.section-content {

    .sub-titles {
        padding: 8px 20px;
        position: relative;
        letter-spacing: 1.875px;
        display: inline-block;
        border-radius: 10px;
        text-transform: uppercase;
        line-height: 1.3;


        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            opacity: 0.1;
            background: linear-gradient(67deg, rgba(20, 169, 115, 0.80) -41.88%, #1ED699 126.58%);
        }

        @media (max-width: 575.98px) {
            br {
                display: none;
            }
        }

    }

    .banner-sub {
        padding: 5px 25px;

        &::after {
            border-radius: 20px;
            opacity: 0.2;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 3.13%, #FFF 48.44%, rgba(255, 255, 255, 0.00) 97.15%);
            backdrop-filter: blur(200px);
        }
    }

    .secondary-sub-title {
        color: var(--white);
        letter-spacing: 1.875px;

        &::after {
            border-radius: 5px;
            opacity: 0.2;
            background: #B8F5E0;
            backdrop-filter: blur(200px);
        }
    }

    .fs-60 {
        @media (max-width: 767.98px) {
            font-size: 33px;
        }
    }

    .fs-50 {
        @media (max-width: 767.98px) {
            font-size: 30px;
        }
    }

    .under-line {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: 4px;
            width: 100%;
            height: 6px;
            background-image: url("./Assets/images/line/contracts.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            @media (max-width: 575.98px) {
                display: none;
            }
        }
    }

    p.fs-18 {
        padding-top: 15px;
    }

    .section-content-btn {
        display: flex;
        align-items: center;
        gap: 16px;
        padding-top: 30px;

        .btn-lg {
            min-width: 183px;
            padding-left: 35px;
            padding-right: 35px;

            @media (max-width: 575.98px) {
                padding-left: 25px;
                padding-right: 25px;
                min-width: 160px;
                font-size: 14px;
            }
        }
    }
}

// Banner
.banner-area {
    background-image: url("./Assets/images/home/banner-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    p {
        max-width: 580px;
        margin: 0 auto;
    }
}


// passing
.passing-area {
    position: relative;
    z-index: 1;
    background-image: url("./Assets/images/home/passing-bg.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    .under-line::before {
        background-image: url("./Assets/images/line/passion.png")
    }

    @media (max-width: 1199.98px) {
        overflow: hidden;
    }

    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-image: url("./Assets/images/home/passion-bg.png");
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: cover;
    //     z-index: -1;
    // }

    p.fs-18 {
        max-width: 505px;
    }

    .passion-img {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: -57px;
            right: -42px;
            width: 141px;
            height: 138.644px;
            background-image: url("./Assets/images/home/passing-icon.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;

            @media (max-width: 991.98px) {
                display: none;
            }
        }
    }
}

// Technologies
.technologies-area {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        bottom: -69px;
        left: 0;
        width: 130px;
        height: 219px;
        background-image: url("./Assets/images/home/technologie-shape.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .section-content {
        p.fs-17 {
            max-width: 520px;
            margin-left: auto;

            @media (max-width: 991.98px) {
                margin-left: 0;
            }
        }
    }
}

.gallery__item {
    position: relative;
}

.play-btn {
    background: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Future
.future-area {
    .under-line::before {
        background-image: url("./Assets/images/line/future.png")
    }

    .passion-img {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: -57px;
            left: -60px;
            width: 141px;
            height: 138.644px;
            background-image: url("./Assets/images/home/passing-icon.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;

            @media (max-width: 991.98px) {
                display: none;
            }
        }
    }
}

// // ============= About =========
.tradition-area {
    background: #F6FEFB;

    .under-line::before {
        bottom: 0;
    }


    .passion-img {
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            bottom: -106px;
            right: -100px;
            width: 384px;
            height: 377.584px;
            background-image: url("./Assets/images/about/tradition-shap.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;

            @media (max-width: 991.98px) {
                display: none;
            }
        }
    }
}

// // ============= Our Vision =========

.about-page-area {
    .section-content {
        p {
            max-width: 673px;
            margin: 0 auto;
        }
    }
}

.vision-area {
    .under-line::before {
        background-image: url("./Assets/images/line/contractuelle.png")
    }
}

.transparence-area {
    .under-line::before {
        background-image: url("./Assets/images/line/transparence.png");
        bottom: 0;
    }

    .passion-img {
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            bottom: -134px;
            right: -95px;
            width: 299px;
            height: 294px;
            background-image: url("./Assets/images/outvision/shape1.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;

            @media (max-width: 991.98px) {
                display: none;
            }
        }
    }
}


// // ============= Services =========
.contact-nous-area {
    .under-line::before {
        background-image: url("./Assets/images/line/services.png")
    }

    .section-content {
        p.fs-18 {
            max-width: 515px;
        }
    }
}

.transformation-area {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        bottom: -69px;
        right: 0;
        width: 130px;
        height: 219px;
        background-image: url("./Assets/images/services/shape3.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .under-line::before {
        background-image: url("./Assets/images/line/transformation.png")
    }
}

.management-area {
    .under-line::before {
        background-image: url("./Assets/images/line/marche.png")
    }

    .section-content {
        .fs-50 {
            max-width: 798px;
        }

        p.fs-18 {
            max-width: 705px;
            margin: 0 auto;
        }
    }
}

.management-content {
    display: grid;
    gap: 20px;

    .management-item {
        border-radius: 20px;
        background: var(--white);
        box-shadow: 0px 12px 40px 0px rgba(8, 32, 38, 0.05);
        display: grid;
        grid-template-columns: 60px 1fr;
        padding: 28px 20px;
        padding-right: 50px;
        position: relative;

        &:last-child {
            &::before {
                display: none;
            }
        }

        &::before {
            content: "";
            position: absolute;
            bottom: -52px;
            left: 49px;
            width: 2px;
            height: 124px;
            background-image: url("./Assets/images/services/shape2.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;
        }

        @media (max-width: 767.98px) {
            padding-right: 20px;
        }

        .number {
            width: 50px;
            height: 50px;
            background: #1CC58C;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            margin: 0 auto;
            color: var(--white);
            font-size: 25px;
            font-weight: 700;
        }

        .text {
            display: grid;
            gap: 12px;
            padding-left: 13px;

            .fs-20 {
                padding-top: 4px;
            }

            .fs-18 {
                line-height: 1.5;
            }
        }
    }
}


.accompagnement-area {
    background: #F6FEFB;
    position: relative;

    .under-line::before {
        background-image: url("./Assets/images/line/accompagnement.png");
        bottom: 0;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80%;
        height: 100%;
        background-image: url("./Assets/images/services/shape1.png");
    }

    .slider-i {
        padding: 0 10px;

        img {
            width: 100%;
        }
    }

    .section-content {
        p {
            max-width: 669px;
            margin: 0 auto;
        }
    }
}

.contact-page-area {
    .under-line::before {
        background-image: url("./Assets/images/line/connestons.png");
        bottom: 0;

    }
}

.customized-area {
    position: relative;

    .under-line::before {
        background-image: url("./Assets/images/line/vous.png");

    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 60%;
        background-image: url('./Assets/images/services/vous.png');
        background-position: center left;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .section-content {
        p.fs-18 {
            max-width: 523px;
        }
    }
}

.ensemble-area {
    .under-line::before {
        background-image: url("./Assets/images/line/ensemble.png");
        bottom: 0;

    }
}